import { useGetCategoriesQuery } from "@/app/services/categories";
import { getLocale } from "@/utils/locale";
import Link from "next/link";

interface Props {
  handleClick: () => void;
}

const NavLeaseMobile = ({ handleClick }: Props) => {
  const locale = getLocale();
  const { data: categories } = useGetCategoriesQuery({
    locale,
    parent: null,
    order_by: null,
    page_size: null,
    only_children: false,
  });

  return (
    <div className="w-full">
      {categories?.map((cat) => (
        <li onClick={handleClick} key={cat.id} className="w-full">
          <Link href={`/${locale}/catalog/${cat.id}/${cat.slug}`}>
            <div className="flex gap-3 items-center h-11 hover:cursor-pointer bg-[#d9e4ea] px-4 rounded-lg">
              <div>{cat.name}</div>
            </div>
          </Link>
        </li>
      ))}
    </div>
  );
};
export default NavLeaseMobile;
