import {
  CategoryApiConstants,
  useGetCategoriesChildQuery,
  useGetCategoriesQuery,
} from "@/app/services/categories";
import classNames from "@/utils/classNames";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CategoryThumbnailType, getThumbnail } from "@/app/dto/category";
import Image from "next/image";
import LoadingCustomComponent from "@/components/Utils/LoadingCustomComponent";
import NavLeaseSkeleton from "./NavLeaseSkeleton";
import NavLeaseSubCategorySkeleton from "./NavLeaseSubCategorySkeleton";

interface Props {
  handleClose: () => void;
}

const NavLease = ({ handleClose }: Props) => {
  const { locale, push } = useRouter();
  const [currentCategory, setCurrentCategory] = useState<number | null>(null);
  const [currentCategorySlug, setCurrentCategorySlug] = useState<string | null>(
    null
  );

  const { data: categories, isLoading: isLoadingCategories } =
    useGetCategoriesQuery({
      locale,
      parent: null,
      order_by: null,
      page_size: null,
      only_children: false,
    });

  const { data: subcategories, isLoading: isLoadingSubCategories } =
    useGetCategoriesChildQuery(
      {
        locale,
        goPage: {
          order_by: CategoryApiConstants.CATEGORY_TYPE_LEASE,
          page_size: 24,
          parent_ids: [`${currentCategory}`],
        },
      },
      { skip: currentCategory === null }
    );

  useEffect(() => {
    if (categories && !currentCategory) {
      setCurrentCategory(categories[0].id);
      setCurrentCategorySlug(categories[0].slug);
    }
  });

  const getSubcategoriesSize = () => {
    if (!subcategories) return 0;
    return subcategories?.results.length > 20
      ? 20
      : subcategories?.results.length;
  };

  return (
    <>
      <div className="absolute inset-0 flex" aria-hidden="true">
        <div className="w-full bg-white" />
      </div>
      <div className="relative mx-auto grid max-w-7xl grid-cols-1">
        <nav
          className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-12 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
          aria-labelledby="catalog-heading"
        >
          <h2 id="catalog-heading" className="sr-only">
            Catalog menu
          </h2>
          <div className="col-span-3 xl:col-span-2 border-r-gray-50">
            <ul className="">
              {isLoadingCategories ? (
                <NavLeaseSkeleton />
              ) : (
                categories.map((cat) => (
                  <li
                    onClick={() => {
                      setCurrentCategory(cat.id);
                      setCurrentCategorySlug(cat.slug);
                    }}
                    key={cat.id}
                    className={classNames(
                      currentCategory === cat.id ? "bg-alice-blue" : "",
                      "mb-2 px-2 py-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                    )}
                  >
                    {cat.name}
                  </li>
                ))
              )}
              <li
                onClick={() => {
                  push(`/${locale}/catalog/all`);
                  handleClose();
                }}
                className="mb-2 px-2 py-1 hover:bg-alice-blue rounded-md text-safety-orange cursor-pointer"
              >
                <Trans>See all catalog</Trans>
              </li>
            </ul>
          </div>
          <div className="col-span-9 xl:col-span-7">
            <ul className="flex flex-col items-start gap-2 flex-wrap max-h-[450px]">
              {isLoadingSubCategories ? (
                <NavLeaseSubCategorySkeleton />
              ) : (
                subcategories?.results
                  .slice(0, getSubcategoriesSize())
                  .map((cat) => (
                    <li
                      key={cat.id}
                      onClick={() => {
                        push(
                          `/${locale}/category/${encodeURIComponent(cat.slug)}`
                        );
                        handleClose();
                      }}
                      className="flex items-top w-[33%] px-2 py-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                    >
                      <div className="relative rounded-full w-8 h-8 bg-gray-50 mr-2">
                        <Image
                          src={getThumbnail(cat, CategoryThumbnailType.Small)}
                          layout="fill"
                          alt={cat.title}
                        />
                      </div>
                      {cat.name}
                    </li>
                  ))
              )}
              <li
                onClick={() => {
                  push(
                    `/${locale}/catalog/${currentCategory}/${currentCategorySlug}`
                  );
                  handleClose();
                }}
                className="mb-2 px-2 py-1 hover:bg-alice-blue hover:cursor-pointer rounded-md text-safety-orange"
              >
                <Trans>See all</Trans>
              </li>
            </ul>
          </div>
          <div className="col-span-3 hidden xl:block">
            <div className="flex flex-wrap gap-4 justify-end">
              {subcategories?.results.slice(0, 4).map((sub) => (
                <div
                  onClick={() => {
                    push(`/${locale}/category/${encodeURIComponent(sub.slug)}`);
                    handleClose();
                  }}
                  key={sub.id}
                  className="w-32 hover:cursor-pointer"
                >
                  <div className="h-32 w-32 bg-gray-50 rounded-md relative">
                    <Image
                      src={getThumbnail(sub, CategoryThumbnailType.Small)}
                      layout="fill"
                      alt={sub.title}
                    />
                  </div>
                  <h4>{sub.name}</h4>
                </div>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavLease;
