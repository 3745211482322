import {
  useGetCategoriesQuery,
  useGetSupplierProductsForSaleQuery,
} from "@/app/services/categories";
import classNames from "@/utils/classNames";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Image from "next/image";
import NavLeaseSubCategorySkeleton from "./NavLeaseSubCategorySkeleton";
import NavLeaseSkeleton from "./NavLeaseSkeleton";

interface Props {
  handleClose: () => void;
}

const NavPurchase = ({ handleClose }: Props) => {
  const { locale, push } = useRouter();
  const [currentSaleCategory, setCurrentSaleCategory] = useState<number | null>(
    null
  );
  const [currentSaleCategorySlug, setCurrentSaleCategorySlug] = useState<
    string | null
  >(null);

  const { data: saleCategories, isLoading: isLoadingCategories } =
    useGetCategoriesQuery({
      locale,
      parent: null,
      order_by: null,
      page_size: null,
      only_children: false,
      only_for_sale: true,
    });

  const {
    data: productsForSale,
    isFetching,
    isLoading,
  } = useGetSupplierProductsForSaleQuery(
    {
      locale: locale,
      goPage: {
        page_size: 12,
      },
      filters: {
        categories: [currentSaleCategory],
        order_gallery_by: "-is_featured",
      },
    },
    { skip: currentSaleCategory === null }
  );

  useEffect(() => {
    if (saleCategories && saleCategories.length > 0 && !currentSaleCategory) {
      setCurrentSaleCategory(saleCategories[0].id);
      setCurrentSaleCategorySlug(saleCategories[0].slug);
    }
  });
  return (
    <>
      <div className="absolute inset-0 flex" aria-hidden="true">
        <div className="w-full bg-white" />
      </div>
      <div className="relative mx-auto grid max-w-7xl grid-cols-1">
        <nav
          className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-12 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
          aria-labelledby="catalog-heading"
        >
          <h2 id="catalog-heading" className="sr-only">
            Purchase menu
          </h2>
          <div className="col-span-3 xl:col-span-2 border-r-gray-50">
            {isLoadingCategories ? (
              <NavLeaseSkeleton />
            ) : (
              <ul className="">
                {saleCategories?.map((cat) => (
                  <li
                    onClick={() => {
                      setCurrentSaleCategory(cat.id);
                      setCurrentSaleCategorySlug(cat.slug);
                    }}
                    key={cat.id}
                    className={classNames(
                      currentSaleCategory === cat.id ? "bg-alice-blue" : "",
                      "mb-2 px-2 py-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                    )}
                  >
                    {cat.name}
                  </li>
                ))}
                <li
                  onClick={() => {
                    push(`/${locale}/buy`);
                    handleClose();
                  }}
                  className="mb-2 px-2 py-1 hover:bg-alice-blue rounded-md text-safety-orange cursor-pointer"
                >
                  <Trans>See all catalog</Trans>
                </li>
              </ul>
            )}
          </div>
          <div className="col-span-9 xl:col-span-7">
            <ul className="flex flex-col items-start gap-2 flex-wrap max-h-[450px]">
              {isLoadingCategories ? (
                <NavLeaseSubCategorySkeleton />
              ) : (
                productsForSale?.results.map((cat) => (
                  <li
                    key={cat.id}
                    onClick={() => {
                      push(
                        `/${locale}/buy/${cat.id}/${encodeURIComponent(
                          cat.slug
                        )}`
                      );
                      handleClose();
                    }}
                    className="flex items-top w-[33%] px-2 py-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                  >
                    <div className="relative rounded-full w-8 h-8 bg-gray-50 mr-2 shrink-0">
                      {cat.gallery[0]?.small_url ? (
                        <Image
                          src={cat.gallery[0].small_url}
                          objectFit="cover"
                          layout="fill"
                          alt={cat.name}
                          className="rounded-full"
                        />
                      ) : (
                        <>
                          {cat?.category?.image_url && (
                            <Image
                              src={cat.category.image_url}
                              objectFit="cover"
                              layout="fill"
                              alt={cat.name}
                              className="rounded-full"
                            />
                          )}
                        </>
                      )}
                    </div>
                    {cat.name}
                  </li>
                ))
              )}
              <li
                onClick={() => {
                  push(`/${locale}/buy?categories=${currentSaleCategory}`);
                  handleClose();
                }}
                className="mb-2 px-2 py-1 hover:bg-alice-blue hover:cursor-pointer rounded-md text-safety-orange cursor-pointer"
              >
                <Trans>See all</Trans>
              </li>
            </ul>
          </div>
          <div className="col-span-3 hidden xl:block">
            <div className="flex flex-wrap gap-4 justify-end">
              {productsForSale?.results.slice(0, 4).map((sub) => (
                <div
                  onClick={() => {
                    push(
                      `/${locale}/buy/${sub.id}/${encodeURIComponent(sub.slug)}`
                    );
                    handleClose();
                  }}
                  key={sub.id}
                  className="w-32 hover:cursor-pointer"
                >
                  <div className="h-32 w-32 bg-gray-50 rounded-md relative">
                    {sub.gallery[0]?.small_url ? (
                      <Image
                        src={sub.gallery[0].small_url}
                        objectFit="cover"
                        layout="fill"
                        className="rounded-md"
                        alt={sub.name}
                      />
                    ) : (
                      <>
                        {sub.category.image_url && (
                          <Image
                            src={sub.category.image_url}
                            objectFit="cover"
                            layout="fill"
                            alt={sub.name}
                            className="rounded-full"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <h4>{sub.name}</h4>
                </div>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavPurchase;
