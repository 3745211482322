export default function NavLeaseSkeleton() {
  return (
    <>
      <div className="bg-alice-blue-150 z-1 p-2 pb-3 w-full h-full rounded-md animate-pulse">
        <div className="w-full bg-gray-300 h-6 rounded-md mt-2"></div>
        <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
      </div>
    </>
  );
}
