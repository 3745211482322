const brCodes = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "21",
  "22",
  "24",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "71",
  "73",
  "74",
  "75",
  "77",
  "79",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
];

const countries = [
  { code: "es-CL", label: "Chile", codes: ["+56"] },
  { code: "pt-BR", label: "Brasil", codes: brCodes },
  { code: "es-MX", label: "México", codes: ["+52"] },
];

export const getDialCodeFromCountry = (locale: string) => {
  const codes = countries.find((country) => country.code === locale)?.codes;
  return codes ?? ["+56"];
};

export const getDniFromCountry = (locale?: string | null) => {
  switch (locale) {
    case "es-MX":
      return "XXX NNNNNN X-NN";
    case "pt-BR":
      return "XX.XXX.XXX/0001-XX";
    default:
      return "XX.XXX.XXX - Y";
  }
};

export const getCountryCodeFromLocale = (locale: string) => {
  switch (locale) {
    case "pt-BR":
      return "br";
    case "es-MX":
      return "mx";
    default:
      return "cl";
  }
};

export default countries;
