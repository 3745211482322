export default function NavLeaseSubCategorySkeleton() {
  return (
    <>
      <div className="w-full flex">
        <div className="bg-alice-blue-150 z-1 p-2 pb-3 w-full h-full rounded-md animate-pulse">
          <div className="w-full bg-gray-300 h-6 rounded-md mt-2"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        </div>
        <div className="ml-2 bg-alice-blue-150 z-1 p-2 pb-3 w-full h-full rounded-md animate-pulse">
          <div className="w-full bg-gray-300 h-6 rounded-md mt-2"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md mt-8"></div>
        </div>
      </div>
    </>
  );
}
