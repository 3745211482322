import { Trans, t } from "@lingui/macro";
import classNames from "@/utils/classNames";
import { SearchIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { useGetSearchQuery } from "@/app/services/categories";
import { getLocale } from "@/utils/locale";
import { useRouter } from "next/router";
import { Transition } from "@headlessui/react";

export default function SearchInput() {
  const router = useRouter();
  const [search, setSearch] = useState("");
  const [catId, setCatId] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [noItemsFound, setNoItemsFound] = useState(Boolean);
  const inputSearch = useRef<any>();
  const locale = getLocale();
  const minSearch = 4;
  const [debounce, setDebounce] = useState(null);

  const { data: results, isFetching } = useGetSearchQuery(
    {
      locale,
      query: search,
    },
    { skip: search.length < minSearch }
  );

  useEffect(() => {
    if (
      results?.categories ||
      results?.sub_categories ||
      results?.supplier_products
    )
      setShowResults(true);
  }, [results]);

  useEffect(() => {
    if (
      results?.categories.length === 0 &&
      results?.sub_categories.length === 0 &&
      results?.supplier_products.length === 0
    ) {
      setNoItemsFound(true);
    } else {
      const firstCategory = results?.categories[0];
      const firstSubcategory = results?.sub_categories[0];
      if (firstCategory) {
        setCatId(firstCategory.id);
      } else if (firstSubcategory) {
        setCatId(firstSubcategory.parent_id);
      }
      setNoItemsFound(false);
    }
  }, [
    results?.categories,
    results?.sub_categories,
    results?.supplier_products,
  ]);

  const handleGoToCat = (id) => {
    setShowResults(false);
    router.push(`/${locale}/catalog/all?parent_id=${id}`);
  };

  const handleGoToSubCat = (slug) => {
    setShowResults(false);
    router.push(`/${locale}/category/${encodeURIComponent(slug)}`);
  };
  const handleGoToSupplierProducts = (id, slug) => {
    setShowResults(false);
    router.push(
      `/${locale}/buy/${encodeURIComponent(id)}/${encodeURIComponent(slug)}`
    );
  };

  const handleInput = async () => {
    if (!debounce) {
      const _ = (await import("lodash")).default;
      setDebounce(
        _.debounce(() => {
          if (inputSearch) {
            setSearch(inputSearch.current.value);
          }
        }, 500)
      );
    }
  };

  useEffect(() => {
    return () => {
      debounce?.cancel();
    };
  }, []);

  useEffect(() => {
    const closeResults = (e) => {
      if (e.target.id !== "results" && e.target.id !== "search") {
        setShowResults(false);
      }
    };
    document.addEventListener("click", closeResults);
    return () => {
      document.removeEventListener("click", closeResults);
    };
  }, [inputSearch]);

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      setIsFocused(false);
      setShowResults(false);
      if (catId !== undefined) {
        router.push(`/${locale}/catalog/all?parent_id=${catId}`);
      } else {
        router.push(`/${locale}/catalog/all`);
      }
    }
  };

  return (
    <>
      <Transition
        className="w-screen h-full absolute mr-4"
        show={isFocused}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen absolute bg-black z-1 opacity-50"></div>
      </Transition>
      <div className="w-full lg:max-w-[700px] mx-auto mt-2">
        <label htmlFor="search" className="sr-only">
          <Trans>Search for machinery...</Trans>
        </label>
        <div className="relative">
          <input
            id="search"
            name="search"
            className={classNames(
              false
                ? "rounded-t-md border-b-2 border-b-gummetal"
                : "rounded-md",
              "relative block w-full border-2 border-white bg-alice-blue py-2 pr-10 pl-3 text-base placeholder-gray-500 focus:shadow focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none sm:text-sm focus:border-2 focus:ring-gray-400 focus:border-gray-400 transition ease-in-out delay-150"
            )}
            placeholder={t`Search machine`}
            type="search"
            autoComplete="off"
            ref={inputSearch}
            onChange={handleInput}
            onFocus={() => {
              setIsFocused(true);
              setShowResults(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            onKeyDown={handleEnter}
          />
          <div className="z-[3] pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {!isFetching ? (
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </div>
          {search.length >= minSearch && showResults && (
            <div
              id="results"
              className="absolute top-10 bg-alice-blue rounded-md shadow w-full"
            >
              {results && (
                <>
                  {noItemsFound && (
                    <div className="py-2 px-2">
                      <span className="block pb-2 pt-4 px-2 text-sm text-gray-500">
                        <Trans>No results found</Trans>
                      </span>
                    </div>
                  )}
                  {results.sub_categories.length > 0 && (
                    <ul className="pb-2">
                      <span className="block pb-2 pt-4 px-2 text-sm">
                        <Trans>Leasing</Trans>
                      </span>
                      {results.sub_categories.map((subcat) => (
                        <li
                          key={subcat.id}
                          className="py-2 px-2 hover:bg-alice-blue-150 w-full cursor:pointer flex gap-2 pl-4 text-gray-500 hover:text-gray-900 transition-all"
                        >
                          <span className="material-icons-outlined">
                            search
                          </span>
                          <button
                            onClick={() => handleGoToSubCat(subcat.slug)}
                            className="w-full text-left hover:bg-alice-blue-150"
                          >
                            {subcat.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                  {results.supplier_products.length > 0 && (
                    <ul className="pb-2">
                      <hr className="border-t mx-2 py-2"></hr>
                      <span className="block pb-2 px-2 text-sm">
                        <Trans>Immediate purchase availability</Trans>
                      </span>
                      {results.supplier_products.map((cat) => (
                        <li
                          key={cat.id}
                          className="py-2 px-2 hover:bg-alice-blue-150 w-full cursor:pointer flex gap-2 pl-4 text-gray-500 hover:text-gray-900 transition-all"
                        >
                          <span className="material-icons-outlined">
                            search
                          </span>
                          <button
                            onClick={() =>
                              handleGoToSupplierProducts(cat.id, cat.slug)
                            }
                            className="w-full text-left hover:bg-alice-blue-150"
                          >
                            {cat.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                  {results.categories.length > 0 && (
                    <ul className="pb-2">
                      <hr className="border-t mx-2 py-2"></hr>
                      <span className="block pb-2 px-2 text-sm">
                        <Trans>Products to be quoted</Trans>
                      </span>
                      {results.categories.map((cat) => (
                        <li
                          key={cat.id}
                          className="py-2 px-2 hover:bg-alice-blue-150 w-full cursor:pointer flex gap-2 pl-4 text-gray-500 hover:text-gray-900 transition-all"
                        >
                          <span className="material-icons-outlined">
                            search
                          </span>
                          <button
                            onClick={() => handleGoToCat(cat.id)}
                            className="w-full text-left hover:bg-alice-blue-150"
                          >
                            {cat.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
