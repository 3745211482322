export interface Attribute {
  name: string;
  value: string;
}

export interface Category {
  cta_image_url: string;
  cta_title: string;
  cta_text: string;
  id: number;
  name: string;
  title: string;
  slug: string;
  image_url: string | null;
  floor_price: number;
  hourly_price: number;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
  estimated_transportation_price: number;
  images: CategoryImage[] | null;
  parent_id: number | null;
  parent: Category | null;
  thumbnails: CategoryThumbnail | null;
  attributes: any;
  specs: Attribute[];
}

export interface CategoryImage {
  image: string;
}

export interface CategoryThumbnail {
  image_url: string;
  large_url: string;
  medium_url: string;
  small_url: string;
}

export interface CategoryFilter {
  name: string;
  values: string[];
}

export interface CategoryFilterSelected {
  name: string;
  value: string;
}

export enum CategoryThumbnailType {
  Original = "original",
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export interface SearchResults {
  supplier_products: Category[];
  categories: Category[];
  sub_categories: Category[];
}

export function getImageFromCategory(
  category: Category,
  type: CategoryThumbnailType
): string | null {
  if (category?.thumbnails) return getThumbnail(category, type);
  if (category?.image_url) return category.image_url;
  if (category?.parent?.image_url) return category.parent.image_url;
  if (category?.images?.length > 0) return category.images[0].image;
  return "/iso.svg";
}

export function getAllImagesFromCategory(
  category: any,
  type: CategoryThumbnailType
): string[] {
  const images: string[] = [];

  if (category?.thumbnails) images.push(getThumbnail(category, type));
  else if (category.gallery)
    category.gallery.forEach((image) =>
      images.push(getThumbnailGallery(image, type))
    );
  else if (category?.image_url) images.push(category.image_url);
  else if (category?.parent?.image_url) images.push(category.parent.image_url);

  if (category?.images?.length > 0) {
    category.images.forEach((item) => {
      images.push(item.image);
    });
  }
  return images;
}

export function getThumbnailGallery(
  image: CategoryThumbnail,
  type: CategoryThumbnailType
): string | null {
  if (!image) return;
  switch (type) {
    case CategoryThumbnailType.Large:
      return image.large_url;
    case CategoryThumbnailType.Medium:
      return image.medium_url;
    case CategoryThumbnailType.Small:
      return image.small_url;
    default:
      return image.image_url;
  }
}

export function getThumbnail(
  category: any,
  type: CategoryThumbnailType
): string | null {
  if (!category.thumbnails && !category.gallery) return "/iso.svg";

  const kind = category.thumbnails ? "thumbnails" : "gallery";

  switch (type) {
    case CategoryThumbnailType.Large:
      return category[kind].large_url;
    case CategoryThumbnailType.Medium:
      return category[kind].medium_url;
    case CategoryThumbnailType.Small:
      return category[kind].small_url;
    default:
      return category[kind].image_url;
  }
}
